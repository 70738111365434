import {
  _LOCATION_BUDGET_CORE,
  _LOCATION_BUDGET_SUB_PARAMS,
  _LOCATION_BUDGET_SUB_VARIABLES,
} from './locationBudget';
import { _LOCATION_DEFAULT_PLAYBOOK } from './playbook';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _LOCATIONS_PARAMS = `
  $accountOwner: String
  $budgetPeriodFilter: String
  $budgetUtilization: BudgetUtilizationFilter
  $excludeUuids: [String]
  $hasUnapproved: Boolean
  $hasUnsetBudgetFor: String
  $limit: Int
  $market: Int
  $markets: [Int]
  $organization: String
  $paymentTypes: [String]
  $retailerCategories: [String]
  $retailers: [String]
  $retailerSizes: [String]
  $status: [String]
  $tags: [String]
  $types: [String]
  $uuid: String
  $uuids: [String]
  $whereUserMissingPermissions: String
  $withPermissionsFor: String
  ${_PAGING_PARAMS}
`;

const _LOCATIONS_VARIABLES = `
  accountOwner: $accountOwner
  budgetPeriodFilter: $budgetPeriodFilter
  budgetUtilization: $budgetUtilization
  excludeUuids: $excludeUuids
  hasUnapproved: $hasUnapproved
  hasUnsetBudgetFor: $hasUnsetBudgetFor
  limit: $limit
  market: $market
  markets: $markets
  organization: $organization
  paymentTypes: $paymentTypes
  retailerCategories: $retailerCategories
  retailers: $retailers
  retailerSizes: $retailerSizes
  status: $status
  tags: $tags
  types: $types
  uuid: $uuid
  uuids: $uuids
  whereUserMissingPermissions: $whereUserMissingPermissions
  withPermissionsFor: $withPermissionsFor
  ${_PAGING_VARIABLES}
`;

export const _LOCATION_RETAILER = `
  retailer {
    logoImage
    name
    uuid
  }
`;

const _LOCATION_MARKET = `
  market {
    id
    name
    state
  }
`;

const _LOCATION_CORE = `
  name
  uuid
  isActive
  type
  timezone
  phoneNumber
  storeEmail
  paymentType
  mapImg
  status
  parking
  organization {
    uuid
  }
  ${_LOCATION_RETAILER}
  ${_LOCATION_MARKET}
`;

const _LOCATION_ADDRESS = `
  address {
    address1
    address2
    city
    state
    zip
  }
`;

const _LOCATION_METRICS = `
  totalHoursThisMonth
  totalHoursUpcomingMonth
  totalHoursAllTime
  latestShiftDate
  retailerAverageRating
  outstandingRetailerReviews
`;

export const _LOCATION_COMPLIANCE_FULL = `
  compliance {
    name
    shiftStartDelta
    shiftGapDelta
    maxConsecutiveWorkDays
    maxShiftsPerDay
    maxHoursPerDay
    shiftMaxLength
    shiftMinLength
    preFlexViewDisclaimers {
      name
    }
    preFlexClaimDisclaimers {
      name
    }
    requiredWorkerPaperwork {
      name
    }
    retailerPreFlexCancelDisclaimers {
      name
    }
    breakRules {
      breakLength
      paid
      requiresTimestamps
      shiftMinutesToRequireBreak
      strictBreakDuration
    }
    cancellationPayout {
      amounts {
        amount
        timing
        unit
      }
    }
    benefitCriterias {
      name
      benefitType
      annualAccrualCap
      rolloverCap
      rate
      minDaysSinceFirstShiftToAccrue
      minDaysSinceFirstShiftToRedeem
      minDaysSinceFirstShiftOnPlatformToRedeem
      minDaysWorkedOnPlatformToAccrue
    }
  }
`;

const _LOCATION_TERMS = `
  terms {
    paymentType
    customer {
      accountOwner {
        firstName
        lastName
      }
    }
  }
`;

const _LOCATION_ORGANIZATION = `
  organization {
    uuid
    name
  }
`;

const _LOCATION_RELATIONS = `
  favorites {
    uuid
    firstName
    lastName
    preferredName
    profileImg
  }
  approved {
    uuid
    firstName
    lastName
    preferredName
    profileImg
  }
  blocked {
    uuid
    firstName
    lastName
    preferredName
    profileImg
  }
`;

const _LOCATION_TAGS = `
  tags {
    id
    name
    isListVisible
  }
`;

const _LOCATION_NOTES = `
  notes {
    uuid
    content
    updatedAt
    type
    targetUuid
    createdBy{
      firstName
      lastName
    }
  }
`;

const _LOCATION_PERMISSIONS_USERS = `
  approvedUsers {
    user {
      uuid
      firstName
      lastName
    }
    permissions
  }
`;

const _LOCATION_COMMUNICATION_PREFERENCES_USERS = `
  communicationUsers {
    user { uuid }
    types
  }
`;

export const RETAILER_LOCATION_CORE_QUERY = `
  query RETAILER_LOCATION_CORE_QUERY(${_LOCATIONS_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      ${_LOCATION_CORE}
    }
  }
`;

export const RETAILER_LOCATION_OVERVIEW_QUERY = `
   query RETAILER_LOCATION_OVERVIEW_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_CORE}
        ${_LOCATION_ADDRESS}
        ${_LOCATION_TERMS}
        ${_LOCATION_ORGANIZATION}
      }
   }
`;

export const RETAILER_LOCATION_METRICS_QUERY = `
   query RETAILER_LOCATION_METRICS_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_CORE}
        ${_LOCATION_METRICS}
      }
   }
`;

export const RETAILER_LOCATION_WORKERS_QUERY = `
   query RETAILER_LOCATION_WORKERS_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_RELATIONS}
      }
   }
`;

export const RETAILER_LOCATION_NOTES_QUERY = `
   query RETAILER_LOCATION_NOTES_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_NOTES}
      }
   }
`;

export const RETAILER_LOCATION_PLAYBOOKS_QUERY = `
   query RETAILER_LOCATION_PLAYBOOKS_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        uuid
        name
        retailer { name }
        ${_LOCATION_DEFAULT_PLAYBOOK}
      }
   }
`;

export const RETAILER_LOCATION_COMPLIANCE_QUERY = `
   query RETAILER_LOCATION_COMPLIANCE_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_COMPLIANCE_FULL}
      }
   }
`;

export const RETAILER_LOCATION_EDIT_TAGS_QUERY = `
   query RETAILER_LOCATION_EDIT_TAGS_QUERY(${_LOCATIONS_PARAMS}) {
      locations (${_LOCATIONS_VARIABLES}) {
        ${_LOCATION_CORE}
        ${_LOCATION_TAGS}
      }
   }
`;

export const RETAILER_LOCATION_PERMISSIONS_QUERY = `
  query RETAILER_LOCATION_PERMISSIONS_QUERY(
    ${_LOCATIONS_PARAMS}
  ) {
    locations (
      ${_LOCATIONS_VARIABLES}
    ) {
      uuid
      name
      timezone
      organization { uuid }
      ${_LOCATION_RETAILER}
      ${_LOCATION_PERMISSIONS_USERS}
      ${_LOCATION_COMMUNICATION_PREFERENCES_USERS}
    }
  }
`;

export const RETAILER_LOCATIONS_ADD_ACCESS_QUERY = `
  query RETAILER_LOCATIONS_ADD_ACCESS_QUERY(${_LOCATIONS_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      uuid
      name
      timezone
      retailer {
        uuid
        name
      }
    }
  }
`;

export const ORGANIZATION_LOCATIONS_QUERY = `
  query ORGANIZATION_LOCATIONS_QUERY(${_LOCATIONS_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      uuid
      name
      timezone
      retailer {
        uuid
        name
      }
    }
  }
`;

export const LOCATION_SPECIAL_REQUESTS_QUERY = `
  query ORGANIZATION_LOCATIONS_QUERY(${_LOCATIONS_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      uuid
      specialRequestsAvailable
    }
  }
`;

export const LOCATION_BUDGETS_CURRENT_QUERY = `
  query LOCATION_BUDGETS_CURRENT_QUERY(${_LOCATIONS_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      uuid
      name
      timezone
      ${_LOCATION_RETAILER}
      
    }
  }
`;

export const LOCATIONS_WITH_BUDGETS_QUERY = `
  query LOCATIONS_WITH_BUDGETS_QUERY(${_LOCATIONS_PARAMS}${_LOCATION_BUDGET_SUB_PARAMS}) {
    locations (${_LOCATIONS_VARIABLES}) {
      uuid
      name
      timezone
      retailer {
        uuid
        name
        logoImage
      }
      locationBudgets(${_LOCATION_BUDGET_SUB_VARIABLES}) {
        ${_LOCATION_BUDGET_CORE}
        budgetPeriod {
          name
        }
      }
      myPermissions
    }
  }
`;

export const RETAILER_LOCATIONS_LIST_QUERY = `
  query RETAILER_LOCATIONS_LIST_QUERY(${_LOCATIONS_PARAMS}) {
    locations(${_LOCATIONS_VARIABLES}) {
      name
      uuid
      isActive
      status
      type
      storeEmail
      parking
      compliance {
        maxShiftsPerDay
        shiftStartDelta
        shiftGapDelta
        shiftMaxLength
        shiftMinLength
      }
      timezone
      terms {
        paymentType
        customer {
          accountOwner {
            firstName
          }
        }
      }
      retailer {
        logoImage
        name
        uuid
      }
      market {
        id
        name
      }
    }
  }
`;

export const RETAILER_LOCATIONS_FOR_SELECT = `
  query RETAILER_LOCATIONS_LIST_QUERY(${_LOCATIONS_PARAMS}) {
    locations(${_LOCATIONS_VARIABLES}) {
      name
      uuid
      isActive
      status
      type
      compliance {
        maxShiftsPerDay
        shiftStartDelta
        shiftGapDelta
        shiftMaxLength
        shiftMinLength
      }
      timezone
      terms {
        paymentType
      }
      retailer {
        logoImage
        name
        uuid
      }
      market {
        id
        name
      }
    }
  }
`;

export const RETAILER_LOCATION_IDS_LIST_QUERY = `
  query RETAILER_LOCATION_IDS_LIST_QUERY {
    locations {
      uuid
      name
      retailer {
        name
        uuid
      }
    }
  }
`;

interface RetailerLocationQueryProps {
  includeWorkers?: boolean;
  includeTags?: boolean;
  includeCompliance?: boolean;
}

const RetailerLocationPropsDefaults = {
  includeWorkers: false,
  includeTags: false,
  includeCompliance: false,
};

// @deprecated: do not use for new queries
export const RETAILER_LOCATION_QUERY = (
  props: RetailerLocationQueryProps = RetailerLocationPropsDefaults,
) => {
  const { includeWorkers, includeTags, includeCompliance } = props;
  return `
    query RETAILER_LOCATION_QUERY($uuid: String!) {
      locations (uuid: $uuid) {
        name
        uuid
        phoneNumber
        storeEmail
        isActive
        status
        type
        paymentType
        mapHref
        createdAt
        timezone
        totalHoursThisMonth
        totalHoursUpcomingMonth
        totalHoursAllTime
        latestShiftDate
        retailerAverageRating
        outstandingRetailerReviews
        address {
          address1
          address2
          city
          state
          zip
        }
        retailer {
          logoImage
          name
          uuid
        }
        market {
          name
          id
        }
        notes {
          uuid
          content
          updatedAt
          type
          targetUuid
          createdBy{
            firstName
            lastName
          }
        }
        terms {
          customer {
            accountOwner {
              firstName
              lastName
            }
          }
        }
        ${
          includeTags
            ? `
          tags {
            id
            name
            isListVisible
          }
        `
            : ''
        }
        ${
          includeWorkers
            ? `
          favorites {
            uuid
            firstName
            lastName
            preferredName
            profileImg
          }
          approved {
            uuid
            firstName
            lastName
            preferredName
            profileImg
          }
          blocked {
            uuid
            firstName
            lastName
            preferredName
            profileImg
          }
        `
            : ''
        }
        ${includeCompliance ? `${_LOCATION_COMPLIANCE_FULL}` : ''}
      }
    }
  `;
};

export const RETAILER_LOCATION_RELATIONS_USERS_QUERY = `
  query RETAILER_LOCATION_RELATIONS_USERS_QUERY(${_LOCATIONS_PARAMS}) {
    locations(${_LOCATIONS_VARIABLES}) {
      name
      uuid
      retailer {
        name
        uuid
      }
      favorites {
        uuid
      }
      approved {
        uuid
      }
      blocked {
        uuid
      }
    }
  }
`;

export const RETAILER_LOCATION_TAGS_QUERY = `
  query retailerLocationTagsQuery($uuid: String!) {
    locations(uuid: $uuid) {
      uuid
      name
      retailer {
        name
        uuid
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const OZ_LOCATION_MEDIA_QUERY = `
query retailerLocationTagsQuery($uuid: String!) {
    locations(uuid: $uuid) {
      uuid
      name
      retailer {
        name
        uuid
      }
    }
  }
`;
