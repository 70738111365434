import { _LOCATION_RETAILER } from '../location';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';
import {
  _PERMISSION_USERS_CORE,
  _USERS_PARAMS,
  _USERS_VARIABLES,
} from './user.helpers';

export const OZ_RETAILER_SPECIAL_REQUEST_WORKERS_QUERY = `
  query RETAILER_SELECT_WORKERS_QUERY(
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $favoriteAt: [String]
    $hasLocationRelation: Boolean
    $hasWorked: Boolean
    $locations: [String]
    $uuids: [String]
    ${_PAGING_PARAMS}
  ) {
    users(
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      favoriteAt: $favoriteAt
      hasLocationRelation: $hasLocationRelation,
      hasWorked: $hasWorked
      locations: $locations 
      uuids: $uuids
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      profileImg
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      lastShiftDate
      lastDateWorkedAtLocation
      totalShiftsAtLocation
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
        }
      }
    }
  }
`;

export const OZ_RETAILER_USERS_LIST = `
  query usersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      ${_PERMISSION_USERS_CORE}
      isRetail
      isWorker
      isSupport
      lastLogin
      organization {
        uuid
        name
      }
      locationsWithAccess {
        uuid
        name
        ${_LOCATION_RETAILER}
      }
    }
  }
`;

export const ORGANIZATION_USERS_QUERY = `
  query usersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      ${_PERMISSION_USERS_CORE}
      locationsWithAccess {
        uuid
        name
        ${_LOCATION_RETAILER}
      }
    }
  }
`;

export const RETAILER_PERMISSION_PREFERENCES_SETTINGS_USERS_EXPANDED = `
  query usersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      ${_PERMISSION_USERS_CORE}
      phoneNumber
      email
      locationPermissions {
        location { uuid }
        permissionType
      }
      locationCommunicationPreferences {
        location { uuid }
        type
      }
    }
  }
`;
